import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

import apartmentCompartmentalizaiton from "../pdf/resources-technical-resources/01-Apartment-Compartmentalization-with-Aerosol-Based-Sealing-Process-DOE-July-2015.pdf"

import californiaBuilder from "../pdf/resources-technical-resources/02-California-Builder-1-Phase-I-Report.pdf"
import demonstratingEffectiveness from "../pdf/resources-technical-resources/03-Demonstrating-the-Effectiveness-of-an-Aerosol-Sealant-to-Reduce-Multi-Unit-Dwelling-Envelope-Air-Leakage.pdf"
import estcp from "../pdf/resources-technical-resources/04-ESTCP-Cost-Performance-Report-Automated-Aerosol-Sealing-of-Building-Envelopes.pdf"
import fieldTrial from "../pdf/resources-technical-resources/05-Field-Trial-of-an-Aerosol-Based-Enclosure-Sealing-Technology-DOE-Sept-2015.pdf"
import labTesting from "../pdf/resources-technical-resources/06-Laboratory-Testing-of-Aerosol-for-Enclosure-Air-Sealing-DOE-May-2012.pdf"
import minnesotaBuilder from "../pdf/resources-technical-resources/07-Minnesota-Builder-Phase-I-Report.pdf"
import durabilityTesting from "../pdf/resources-technical-resources/08-AeroBarrier-Durability-Testing-3.pdf"
import productDataSheet from "../pdf/resources-technical-resources/09-AeroBarrier-Product-Data-Sheet.pdf"




const aboutTheAuthor = props => (
  <Layout>
        <Seo title="Technical Resources"/>
        <section className="page-section">

        <Container>

        <h1>Technical Resources</h1>

        <h2>Research</h2>
        <ul className="list-unstyled">
          <li>
            <a href={apartmentCompartmentalizaiton}>Apartment Compartmentalization with Aerosol Based Sealing Process – DOE July 2015</a>
          </li>
          <li>
            <a href={californiaBuilder}>California Builder #1 Phase I Report</a>
          </li>
          <li>
            <a href={demonstratingEffectiveness}>Demonstrating the Effectiveness of an Aerosol Sealant to Reduce Multi-Unit Dwelling Envelope Air Leakage</a>
          </li>
          <li>
            <a href={estcp}>ESTCP Cost Performance Report – Automated Aerosol-Sealing of Building Envelopes</a>
          </li>
          <li>
            <a href={fieldTrial}>Field Trial of an Aerosol Based Enclosure Sealing Technology – DOE Sept 2015</a>
          </li>
          <li>
            <a href={labTesting}>Laboratory Testing of Aerosol for Enclosure Air Sealing – DOE May 2012</a>
          </li>
          <li>
            <a href={minnesotaBuilder}>Minnesota Builder Phase I Report</a>
          </li>
        </ul>

        <h2>Sealant Information</h2>
            <ul className="list-unstyled">
              <li>
                <a href={durabilityTesting}>AeroBarrier Durability Testing</a>
              </li>
              <li>
                <a href={productDataSheet}>AeroBarrier Product Data Sheet</a>
              </li>
            </ul>

        </Container>
        
        </section>
        
      </Layout>
);

export default aboutTheAuthor;

